header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 0 30px;
  background-color: #fff;
  position: relative;
  z-index: 100;
  @include mq-max(md){
    display: block;
    padding: 0;
    position: fixed;
    top: 0;
    background: transparent;
  }
  h1,.logo{
    margin-bottom: 0;
    margin-right: 30px;
    line-height: 1;
    display: inline-block;
    width: 200px; // 状況によって変更可
    @include mq-max(md){
      margin: 0;
      line-height: 0;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }

  .navHeader{
    position: relative;
    @include mq-max(md){
      padding: 10px;
      background-color: #fff;
      line-height: 0;
      z-index: 100;
      .open & {
        box-shadow: 0 1px 3px transparentize($textColor, .8);
      }
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  #toggle{
    @include mq-max(md){
      display: block;
      // position: fixed;    /* bodyに対しての絶対位置指定 */
      // right: 13px;
      // top: 12px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 42px;
      height: 42px;
      cursor: pointer;
      z-index: 3;
      span{
        display: block;
        position: absolute;
        width: 30px;
        border-bottom: solid 3px #000;
        transition: .35s ease-in-out;			/*変化の速度を指定*/
        left: 6px;
        &:nth-child(1){
          top: 9px;
        }
        &:nth-child(2){
          top: 18px;
        }
        &:nth-child(3){
          top: 27px;
        }
      }
      &.active{
        span{
          &:nth-child(1){
            top: 18px;
            left: 6px;
            transform: rotate(-45deg);
          }
          &:nth-child(2),&:nth-child(3){
            top: 18px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
