//沿革

.timeline {
  width: 100%;
  margin: 0 auto;
  list-style-type: none;

  li {
    margin: 0;
    position: relative;
    @include mq-max(sm) {
      padding-left: 1px;
      border-left: 2px solid $keyColor;
    }

    .grid {
      [class*='col-'] {
        padding: 0 20px 0;
      }
    }

    .timeline-date {
      position: relative;
      padding-bottom: 20px;
      @include mq-max(sm){
        padding-bottom: 0;
      }

      &:before {
        content: '';
        width: 12px;
        height: 12px;
        background: $keyColor;
        position: absolute;
        right: -7px;
        top: 20px;
        border-radius: 100%;
        z-index: 3;
        @include mq-max(sm) {
          left: -4px;
        }
        @include mq-max(sm) {
          top: 18px;
        }

      }

      &.now {
        &:before {
          background: $keyColor;
          top: 30px;
        }
        p {
          position: relative;
          top: 10px;
          @include mq-max(sm) {
            top: 13px;
          }
        }
      }
      p {
        font-weight: 600;
        padding-top: 10px;
        margin: 0;
        width: 10em;
        text-align: right;
        margin-left: auto;
        font-size: 16px;
        @include mq-max(sm) {
          padding-left: 10px;
          text-align: left;
          margin-left: 0;
        }
      }

    }

    .timeline-content {
      border-left: 2px solid $keyColor;
      padding-bottom: 20px !important;

      &.now {
        position: relative;
        &:before {}
        p {
          position: relative;
          top: 10px;
        }
      }

      @include mq-max(sm) {
        border: none;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        padding-top: 10px;
        @include mq-max(sm) {
          padding-left: 10px;
          padding-top: 0;
        }
      }
    }
  }
}