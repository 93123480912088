//タブ

.tab {
  overflow: hidden;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  //注意書き表示
  &.add_attention {
    &:before {
      @include mq-max(sm) {
        content: 'スライドでタブを移動できます→';
        display: block;
        font-size: 12px;
        padding-bottom: 20px;
      }
    }
  }
  &_select {
    display: flex;
    align-items: baseline;
    @include mq-max(sm) {
      overflow-x: scroll;
    }
  }

  &_item {
    max-width: 100%;
    width: 100%;
    position: relative;
    border-top: 2px solid $bdrColor;
    border-left: 2px solid $bdrColor;
    cursor: pointer;
    &:last-child {
      border-right: 2px solid $bdrColor;
    }
    @include mq-max(sm) {
      min-width: calc(100% / 3);
    }
    @include mq-max(xs) {
      min-width: 50%;
    }
    &>span {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      padding: 20px 15px;
      display: block;
    }

    &.active {
      border: 2px solid $keyColor;
      border-bottom: none;
      &>span {
        padding-top: 30px;
      }
      &+.tab_item {
        border-left: none;
      }
    }
    &:not(.active) {
      &:before {
        content: '';
        width: calc(100% + 4px);
        height: 2px;
        background-color: $keyColor;
        display: block;
        position: absolute;
        left: -2px;
        bottom: 0;
        z-index: 5;
      }
      &:first-child {
        border-left: 2px solid $bdrColor;
        &:before {
          left: -2px;
        }
      }
    }
  }

  &_body {
    padding: 30px;
  }

  &_article {
    display: none;
    &.show {
      display: block;
    }
    p {
      font-size: 16px;
      line-height: 1.6;
    }
  }
}