///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.btn {
  display: block;
  color: $white;
  line-height: 1.25;
  padding: 10px 40px;
  border: 2px solid $accentColor;
  border-radius: 5px;
  background-color: $accentColor;
  text-align: center;
  font-weight: 600;
  position: relative;
  font-size: 16px;
  transition: all $transition;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: $accentColor;
    background: $white;
    &:after {
      right: 10px;
    }
  }
  &.btn_round {
    border-radius: 50px;
    #gnavi & {
      padding: 15px 20px;
      transform: translateY(20px);
      @include mq-max(md) {
        width: auto;
        margin: 10px;
        transform: translateY(0);
      }
    }
  }

  //PDF
  &[href$=".pdf"] {
    &:hover {
      &:after {
        right: 16px;
        color: #fff;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &:after {
      color: $keyColor;
    }
    &:hover {
      &:after {
        right: 16px;
        color: #fff;
      }
    }
  }

  //テキスト量で長さ可変
  &_inline {
    display: inline-block;
    width: auto;
    @include mq-max(xs) {
      width: 100%;
    }
  }

  //小さいボタン
  &_small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  PFWForm

///////////////////////////////////////////////////////////////////////////////////////////////////////
.btn_area{
  width: $contentWidth;
  margin: 40px auto;
  text-align: center;
  @include mq-max(md) {
    margin: 0 auto;
  }
}

button.btn_form,
a.btn_form{
    min-width: $btn-width;
    padding: 20px 40px;
    margin-bottom: 10px;
    font-size: 130%;
    display: inline-block;
    background: $keyColor;
    text-align: center;
    border: $border-btn-width solid  $border-btn;
    color: $border-txt-color;
    transition: $transition;
    border-radius: $border-radius;
    text-decoration: none;
    position: relative;
    @include mq-max(sm) {
      display: inline-block;
      min-width: inherit;
      width: 100%;
      margin: 0 0 20px 0;
    }

    &:before{
      content: "\f054";
      font-family: "FontAwesome";
      position: absolute;
      right: 20px;
      top: 24px;
    }

    &:hover{
      background: darken( $keyColor, 15% );
    }

    &.back{
      width: auto;
      min-width: inherit;
      background: #fff;
      border: 1px solid #ccc;
      color: $black;
      font-size: 120%;
      padding-left: 60px;
      margin-right: 20px;
      @include mq-max(sm) {
        display: inline-block;
        min-width: inherit;
        width: 100%;
        margin: 0 0 20px 0;
      }

      &:hover{
        background: lighten( $gray, 15% );
      }

      &:before{
        content: "\f053";
        right: inherit;
        left: 20px;
      }
    }
}
