//全体共通で使うCSS定義
html {
  &.open {
    overflow: hidden;
    body {
      overflow: hidden;
    }
  }
}

body {
  word-wrap: break-word;
  font-family: $sanserif;
  font-size: 16px;
  color: $textColor;
  line-height: $lineHeight;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pkna";
  overflow-x: hidden;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出し

///////////////////////////////////////////////////////////////////////////////////////////////////////
h1,h2,h3,h4,h5 {
  font-family: $hannari;
  margin-bottom: 30px;
}
h1 {
  font-size: 30px;
  @include mq-max(xs) {
    font-size: 24px;
  }
}
h2 {
  font-size: 36px;
  @include mq-max(xs) {
    font-size: 22px;
    margin-bottom: 24px;
  }
}
h3 {
  font-size: 21px;
  margin-bottom: 17px;
  @include mq-max(xs) {
    font-size: 20px;
    margin-bottom: 12px;
  }
}
h4 {
  font-size: 20px;
  margin-bottom: 12px;
  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
h5 {
  font-size: 18px;
  margin-bottom: 12px;
  @include mq-max(xs) {
    font-size: 17px;
    margin-bottom: 10px;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  文章

///////////////////////////////////////////////////////////////////////////////////////////////////////
a {
  text-decoration: none;
  color: $linkColor;
  transition: color $transition,
              opacity $transition;
  &:hover {
    color: $keyColor;
  }
  &[href^="javascript:void(0)"] {
    pointer-events: none;
  }
  &[target="_blank"]{
    &:after{
      content: '\f08e';
      color: $keyColor;
      font-family: 'FontAwesome';
      margin-left: 10px;
      font-weight: 600;
      -webkit-font-smoothing:antialiased;
    }
  }
  &[href$=".pdf"] {
    &:after {
      content: '\f1c1';
      color: $red;
      font-family: 'FontAwesome';
      margin-left: 10px;
      font-weight: 600;
      -webkit-font-smoothing:antialiased;
    }
  }
}
p {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 1.5;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(xs) {
    font-size: 15px;
    margin-bottom: 24px;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  強調

///////////////////////////////////////////////////////////////////////////////////////////////////////
strong {
  font-weight: 600;
  //黄色い下線
  &.bdr_yellow {
    background: linear-gradient(transparent 60%, #FFEF69 60%);
  }

  //赤
  &.red {
    color: $red;
  }

  //最も強調する
  &.largest {
    font-size: 150%;
  }
}

.p_large {
  font-family: $serif;
  font-size: 112.5%; // 16px*112.5%=18px
  line-height: 3;
  color: $linkColor;
  font-weight: 500;
  @include mq-max(sm) {
    font-size: 100%;
  }
}



///////////////////////////////////////////////////////////////////////////////////////////////////////

//  画像

///////////////////////////////////////////////////////////////////////////////////////////////////////
figure {
  img {
    width: 100%;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  電話番号CSS

///////////////////////////////////////////////////////////////////////////////////////////////////////
.tel-num {}



