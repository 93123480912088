///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

.newslist {
  margin-bottom: 30px;
  &_item {
    border-top: 1px solid $gray;
    &>a {
      display: flex;
      align-items: baseline;
      padding: 15px 0;
      opacity: 1;
      transition: opacity $transition;
      &:hover {
        color: $keyColor;
        opacity: .7;
      }
      @include mq-max(sm){
        flex-wrap: wrap;
      }
    }
  }
  &_cat, &_date,&_title {
    display: inline-block;
  }
  &_cat {
    padding: 10px 10px;
    min-width: 130px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    border-radius: 5px;
    background-color: $keyColor;
    margin-right: 25px;
    &.cat_news {
      background-color: #7CC5DF;
    }
  }
  &_date {
    color: $textColor;
    margin-right: 15px;
    font-size: 16px;
    font-weight: 600;
    width: 120px;
    @include mq-max(sm){
      width: auto;
    }
  }
  &_title {
    font-size: 16px;
    @include mq-max(sm){
      margin-top: 10px;
      width: 100%;
    }
  }
}