//サイトレイアウト構造を記述

#container {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 87px;
    top: 56px;
    left: 0;
    right: 0;
    background-image: url('/inc/image/common/img_header_wave.png');
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 99;
    @include mq-max(md) {
      position: fixed;
      top: 0;
    }
  }
}
#main {
  background-image: url(/inc/image/common/bg_paper.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}
#contents {
  padding: 50px 0;//お好みで変更
  @include mq-max(sm) {
    padding: 30px 0;//お好みで変更
  }
}

[class*="grid"] {
  @include mq-max(md) {
    margin: 0;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 60px 0;
    @include mq-max(md) {
      padding: 30px 20px 0;
    }
  &:last-child {
    padding: 60px 0 0;
    @include mq-max(md) {
      padding: 60px 20px 0;
    }
  }
  & .section {
    padding: 30px 0;
    @include mq-max(md) {
      padding: 0 0 30px;
    }
    &:last-child {
      padding: 30px 0 0;
    }
  }
}

//background full width
[class^="bg_"] {
  padding: 40px 0;//お好みで変更
  margin-bottom: 120px;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(sm) {
    margin: 0 0 60px;//お好みで変更
  }
}

.section.bg_keyColor {
  position: relative;
  max-width: 100vw;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  padding: 60px calc( calc(100vw - 1200px) / 2 ) 0;
  background-color: $keyColorLight;
    @include mq-max(md) {
      padding: 30px 20px 0;
    }
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-image: url(/inc/image/common/bg_sectionkeycolor_top.png);
    background-size: cover;
    max-width: 100%;
    width: 100%;
    left: 50vw;
    top: -57px;
    height: 57px;
    transform: translateX(-50vw);
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url(/inc/image/common/bg_sectionkeycolor_bottom.png);
    background-size: cover;
    max-width: 100%;
    width: 100%;
    left: 50vw;
    bottom: -57px;
    height: 57px;
    transform: translateX(-50vw);

  }

}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
.taL {
  text-align: left !important;
}
.taC {
  text-align: center !important;
}
.taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  clearfix

///////////////////////////////////////////////////////////////////////////////////////////////////////
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.alternate {
  &_wrap {
    display: flex;
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
    @include mq-max(xs) {
      flex-direction: column;
      margin-bottom: 30px;
    }
    &:not(.reverse) {
      .alternate_left {
        @include mq-max(xs){
          margin-bottom: 10px;
        }
      }
    }
    &.reverse {
      @include mq-max(xs) {
        flex-direction: column-reverse;
      }
      .alternate_right {
        @include mq-max(xs){
          margin-bottom: 10px;
        }
      }
    }
  }
  &_item {
    width: 50%;
    padding: 0 ($contentPadding / 2);
    @include mq-max(xs) {
      width: 100%;
      padding: 0;
    }
    img {
      width: 100%;
      display: block;
    }
  }
}



