ul {
  list-style-type: none;

  //disc
  &.disc{
    list-style-type: disc;
    padding-left: 1em;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  //疑似要素Ver
  &.disc_custom {
    position: relative;
    padding-left: 1em;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    li {
      &:before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        left: -8px;
        font-size: 25px;
        line-height: 1;
      }
    }
  }

  //count
  &.count{
    counter-reset: count;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    &>li{
      padding-left: 1em;
      position: relative;
      &:before{
        counter-increment: count;
        content:counter(count);
        position: absolute;
        left: 0;
      }
    }
    &.brackets{
      &>li{
        padding-left: 26px;
        &:before{
          content:"("counter(count)")";
        }
      }
    }
  }

  //注釈
  &.comment{
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    li{
      // display: flex;
      padding-left: 1em;
      position: relative;
      &:before{
        content: '※';
        display: inline-block;
        position: absolute;
        left: 0;
      }
    }
  }
}

ol{
  padding-left: 1em;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}