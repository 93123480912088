//変数定義

/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/

//----------------------------------------------------------------
//    レイアウト設定
//----------------------------------------------------------------
$contentWidth    : 1200px;// 全体のコンテンツ幅
$contentPadding  : 20px;//パディング:gridlexでも使用
$tablet-break    : 768px;//タブレットでのブレイクポイント
$mobile-break    : 568px;//モバイルでのブレイクポイント
$padding-break   : 40px;//基本のコンテンツ余白
$padding-content : ($padding-break / 2);//テーブルの上下などの余白指定


/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/

//全体をレスポンシブ非対応にする時は false
$responsiveMode : true;


// max-width(gridlexに合わせる)
$breakpoint-max: (
  'xs': 'screen and (max-width: 568px)', //スマホ境界
  'sm': 'screen and (max-width: 768px)', //タブレット境界
  'md': 'screen and (max-width: 1024px)',
  'lg': 'screen and (max-width: 1280px)',
) !default;

// min-width(gridlexに合わせる)
$breakpoint-min: (
  'xs': 'screen and (min-width: 568px)', //スマホ境界
  'sm': 'screen and (min-width: 768px)', //タブレット境界
  'md': 'screen and (min-width: 1024px)',
  'lg': 'screen and (min-width: 1280px)',
) !default;


/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/

$white           : #FFFFFF;
$gray            : #CCCCCC;
$black           : #000000;
$gray-l          : lighten( $gray, 15%);
$red             : #c82e2e;

$keyColor        : #73CC8A; //キーカラー
$keyColorLight   : #C4F6E1;
$linkColor       : #1C4131;
$accentColor     : #E1AAB7; //アクセントカラー

$font-color      : $black;
$bdrColor        : #000;

/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/

$textColor: #444;
$textColorLight: lighten($textColor,10);
$textColorDark: darken($textColor,10);

$lineHeight: 1.4em; // 基本的な行間
$transition: .2s ease;

// サンセリフ・ゴシック体
$sanserif: "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$hannari: "Hannari", sans-serif;
// セリフ・明朝体
$serif: "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

//----------------------------
//    コンテンツ
//---------------------------
$content-bg       : $white;
$font-size        : 90%;//全体のフォントサイズ

//-----------------------------
//    装飾の定義
//-----------------------------
$border-radius   : 3px;//全体の角丸サイズ

//----------------------------------------------------------------
//    フォームの定義
//----------------------------------------------------------------
$padding-parts   : 4px 10px;//input等のform用パーツの余白指定

$border-table    : $gray;//テーブルのボーダーカラー
$border-table-style    : solid;//solid,none,dotted //テーブルのボーダースタイル

$border-parts    : $gray;//inputなどのフォーム用パーツのボーダーカラー

$bg-th           : $gray-l;
$txt-th          : $black;
$bg-must         : $red;     //必須アイコンの色
$bg-optional     : $black;     //任意アイコンの色


//---------------------------------------------------------------
//    errorの定義
//----------------------------------------------------------------
$bg-error       : lighten( $red, 40% );//エラーメッセージの
$txt-error      : $red;//エラーメッセージのテキストカラー
$border-error   : 2px solid $red;//エラーメッセージのテキストカラー

//----------------------------------------------------------------
//    ボタンの定義
//----------------------------------------------------------------

$btn-width       : 350px;//PCサイトアクションボタンの大きさ

//ボタンの装飾
$border-btn          : none;//テーブルのボーダーカラー
$border-btn-width   : 0px;//テーブルのボーダーサイズ
$border-txt-color   : $white;//テーブルのボーダーサイズ
