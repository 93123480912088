#gnavi{
  @include mq-max(md){
    html.open &.active > ul{
      overflow-y: scroll;
      height: calc(100vh - 90px);//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.active{
    transform: translateY(0%);
  }
  @include mq-max(md){
    display: none;
  }
  &>ul{
    padding: 0;
    display: flex;
    @include mq-max(md){
      display: block;
    }
    &>li{
      position: relative;
      &:last-child{
        margin-right: 0;
      }
      @include mq-max(md){
        margin-right: 0;
        border-top: 1px solid $gray;
        &:last-child{
          border-bottom: 1px solid $gray;
          @include mq-max(md) {
            background-color: $white;
          }
        }
      }
      &:first-child {
        border-top: 1px solid transparent;
      }
      a:not(.btn){
        @include mq-max(md) {
          background-color: #fff;
        }
      }
      &>a{
        text-decoration: none;
        display: inline-block;
        padding: 36px 15px;
        @include mq-max(md){
          display: block;
          padding: 15px 10px;
        }
        &:not(.btn) {
          color: $textColor;
          &:hover {
            color: $keyColor;
          }
        }
      }

      //下層メニュー
      &.drop_down{
        &>a{
          position: relative;
          &:after{
            @include mq-max(md){
              content: '\f067';
              font-family: 'FontAwesome';
              position: absolute;
              top: 50%;
              right: 16px;
              transform: translateY(-50%);
            }
          }
          &.active{
            &:after{
              @include mq-max(md){
                content: '\f068';
              }
            }
          }
        }
        &.active {
          .drop_down_menu {
            top: 104px; // headerの高さ
            opacity: 1;
            visibility: visible;
            @include mq-max(md) {
              top: auto;
            }
          }
        }
        .drop_down_menu{
          position: absolute;
          top: (104px - 30px); // headerの高さ - 30px
          visibility: hidden;
          opacity: 0;
          transition: all .5s ease;
          @include mq-max(md){
            position: relative;
            display: none;
            transition: none;
            top: auto;
            opacity: 1;
            visibility: visible;
          }
          li{
            border-bottom: 1px solid $gray;
            &:first-child{
              @include mq-max(md){
                border-top: 1px solid $gray;
              }
            }
            &:last-child{
              border: none;
            }
            a{
              background-color: #fff;
              padding: 10px 15px;
              white-space: nowrap;
              display: block;
              text-decoration: none;
              color: #000;
              width: 100%;
              @include mq-max(md) {
                padding: 15px 10px 15px 25px;
              }
            }
          }
        }
      }
    }
  }
}
